import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { getIn } from "formik";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";

import ButtonStyled from "../../../../components/ButtonStyled";
import FormTextField from "../../../../components/FormTextField";
import { PhoneNumberInput } from "../../../../components/PhoneNumberInput";

import expressunion from "../../../../assets/images/expressunion.png";
import mtn from "../../../../assets/images/mtn.png";
import paypalIcon from "../../../../assets/images/paypal.png";
import orangeV2 from "../../../../assets/images/orangeV2.png";
import smobilpay from "../../../../assets/images/smobilpay.png";
import yoomee from "../../../../assets/images/yoomee.png";
import FormAutoComplete from "../../../../components/FormAutoComplete";
import FormSelectInput from "../../../../components/FormSelectInput";
import { countryList } from "../../../../constants";
import useTranslation from "../../../../customHooks/translations";
import palette from "../../../../styles/palette";
import { CREDIT_CARD, MOBILE_MONEY, PAYPAL } from "../../../../constants/data";
import handleSelectChange from "../utils/handleSelectChange";

const FormDetailsDesktop = ({
  formik,
  payment,
  setPaymentProviderId,
  setPricingId,
  setPaymentProviderName,
  radioChecked,
  setRadioChecked,
  paymentProviderName,
  checked,
  handleChecked,
  theme,
  providerType,
  setProviderType,
}) => {
  const classes = useStyles();
  const translation = useTranslation();

  const isSubmitDisabled = formik.isSubmitting || !checked;
  const isPaypal = formik?.values?.providerType === PAYPAL;

  const [selectedOption, setSelectedOption] = useState(null);
  const defaultValue = countryList.find(
    (item) => item?.name === "Cameroon"
  )
  useEffect(() => {
    if (defaultValue && !selectedOption) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue, selectedOption]);

  return (
    <>
      <Grid
        className={classes.formHeader}
        sx={{
          backgroundColor: theme?.primaryColor
            ? theme.primaryColor
            : palette.primary.main,
        }}
        container
        direction="column"
      >
        <Typography variant="b2" style={{ fontWeight: 600 }}>
          Paiement
        </Typography>
        <Typography variant="b3" style={{ fontWeight: 600 }}>
          Payment
        </Typography>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" className={classes.rootForm}>
          {/* Moyen de paiements */}
          <Typography variant="b3" className={classes.fieldTitle} gutterBottom>
            Moyen de paiement
            <span className={classes.marker}>*</span>
          </Typography>
          <Grid item>
            <FormControl
              className={classes.fieldset}
              component="fieldset"
              fullWidth
            >
              <Typography
                component="legend"
                className={classes.fieldTitleSecondary}
              >
                Payment method
              </Typography>

              <FormSelectInput
                id="providerType"
                name="providerType"
                defaultValue={providerType}
                formik={formik}
                onBlur={formik?.handleBlur}
                placeholder={translation.selectProvider}
                onChange={(e) =>
                  handleSelectChange(e, {
                    formik,
                    setPaymentProviderId,
                    setPricingId,
                    setProviderType,
                  })
                }
                options={payment?.filter(
                  (paymentProvider) => paymentProvider.paymentOptions.length
                )}
              />
            </FormControl>

            {
              <Typography
                pb={4}
                pl={2}
                style={{
                  color: "#d32f2f",
                  fontSize: "0.75rem",
                }}
              >
                {formik.errors.paymentProviderId?.uuid}
              </Typography>
            }
          </Grid>
          {providerType && (
            <>
              {/* Country */}
              {formik?.values?.providerType === MOBILE_MONEY && (
                <Box>
                  <Typography
                    variant="b3"
                    className={classes.fieldTitle}
                    gutterBottom
                  >
                    Pays
                    <span className={classes.marker}>*</span>
                  </Typography>
                  <Grid item>
                    <FormControl
                      className={classes.fieldset}
                      component="fieldset"
                      error
                      fullWidth
                    >
                      <Typography
                        component="legend"
                        className={classes.fieldTitleSecondary}
                      >
                        Country
                      </Typography>
                      <FormAutoComplete
                        fullWidth
                        source="country"
                        options={countryList}
                        defaultValue={countryList.find(
                          (item) => item?.name === "Cameroon"
                        )}
                        disableClearable
                        disabled={true}
                        variant={"outlined"}
                        type={"country"}
                        label={""}
                        onChange={(_, value) => setSelectedOption(value)}
                        selectedOption={selectedOption}
                        size="medium"
                      />
                    </FormControl>

                    {
                      <Typography
                        pb={4}
                        pl={2}
                        style={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                        }}
                      >
                        {formik.errors.paymentProviderId?.uuid}
                      </Typography>
                    }
                  </Grid>
                </Box>
              )}

              {/* Providers */}
              {formik?.values?.providerType && payment && !isPaypal && (
                <Box>
                  <Typography
                    variant="b3"
                    className={classes.fieldTitle}
                    gutterBottom
                  >
                    Opérateur mobile money
                    <span className={classes.marker}>*</span>
                  </Typography>
                  <Grid item>
                    <FormControl
                      className={classes.fieldset}
                      component="fieldset"
                    >
                      <Typography
                        component="legend"
                        className={classes.fieldTitleSecondary}
                      >
                        Mobile money Provider
                      </Typography>
                      {payment
                        ?.find((item) => item?.name === providerType)
                        ?.paymentOptions?.map(
                          (i) =>
                            i?.paymentProviderInfo?.enabled && (
                              <RadioGroup
                                key={i?.paymentProviderInfo?.id?.uuid}
                                aria-required={true}
                                className={classes.radioBlockWrapper}
                                name="paymentProviderId"
                                aria-label="payment"
                                row
                                onChange={() =>
                                  formik.setFieldValue(
                                    "paymentProviderId",
                                    i?.paymentProviderInfo?.id
                                  )
                                }
                                onBlur={formik?.handleBlur}
                                error={
                                  getIn(
                                    formik?.touched,
                                    "paymentProviderId.uuid"
                                  ) &&
                                  !!getIn(
                                    formik?.errors,
                                    "paymentProviderId.uuid"
                                  )
                                }
                                helperText={
                                  getIn(
                                    formik?.touched,
                                    "paymentProviderId.uuid"
                                  ) &&
                                  getIn(
                                    formik?.errors,
                                    "paymentProviderId.uuid"
                                  )
                                }
                                value={getIn(
                                  formik?.values,
                                  "paymentProviderId"
                                )}
                              >
                                <FormControlLabel
                                  key={i?.paymentProviderInfo?.id?.uuid}
                                  id={i?.paymentProviderInfo?.id?.uuid}
                                  onClick={() => {
                                    setPaymentProviderId(
                                      i?.paymentProviderInfo?.id
                                    );
                                    formik.setFieldValue(
                                      "paymentProviderId",
                                      i?.paymentProviderInfo?.id
                                    );
                                    setPricingId(
                                      i?.paymentProviderInfo?.customerPricingId
                                    );
                                    setPaymentProviderName(
                                      i?.paymentProviderInfo?.name
                                    );
                                  }}
                                  value={i?.paymentProviderInfo?.id}
                                  control={
                                    <Radio
                                      name={i?.paymentProviderInfo?.name}
                                      checked={
                                        radioChecked ===
                                          i?.paymentProviderInfo?.name && true
                                      }
                                      onClick={(e) =>
                                        setRadioChecked(e.target.name)
                                      }
                                      className={classes.radio}
                                    />
                                  }
                                  label={
                                    <img
                                      style={{
                                        maxWidth: "50px",
                                        height: "auto",
                                        marginRight: "10px",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        borderRadius: "5px",
                                      }}
                                      src={i?.paymentProviderInfo?.logoUrl}
                                      alt={i?.paymentProviderInfo?.name}
                                      loading="lazy"
                                    />
                                  }
                                />
                              </RadioGroup>
                            )
                        )}
                    </FormControl>
                    {
                      <Typography
                        pb={4}
                        pl={2}
                        style={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                        }}
                      >
                        {formik.errors.paymentProviderId?.uuid}
                      </Typography>
                    }
                  </Grid>
                </Box>
              )}

              {/* Phone number */}
              {!isPaypal && (
                <Grid item>
                  <Typography
                    variant="b3"
                    className={classes.fieldTitle}
                    gutterBottom
                  >
                    {paymentProviderName === "safimoney"
                      ? "ID de portefeuille"
                      : "Numéro mobile money"}
                    <span className={classes.marker}>*</span>
                  </Typography>
                  <Grid item>
                    {paymentProviderName === "safimoney" ? (
                      <FormControl fullWidth>
                        <Typography
                          component="legend"
                          className={classes.fieldTitleSecondary}
                        >
                          Wallet ID
                        </Typography>
                        <Grid item className={classes.inputWrapper}>
                          <FormTextField
                            id="walletId"
                            name="walletId"
                            variant="outlined"
                            className={classes.input}
                            formik={formik}
                          />
                        </Grid>
                      </FormControl>
                    ) : (
                      <FormControl fullWidth>
                        <Typography
                          component="legend"
                          className={classes.fieldTitleSecondary}
                        >
                          Mobile money number
                        </Typography>
                        <Grid item className={classes.inputWrapper}>
                          <PhoneNumberInput
                            id="notification.mobileNumber"
                            name="notification.mobileNumber"
                            variant="outlined"
                            formik={formik}
                            disabled={!radioChecked}
                          />
                        </Grid>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              )}

              {/* Name and Surname */}
              {!isPaypal && (
                <Grid item xl={10} md={12}>
                  <Typography
                    variant="b3"
                    className={classes.fieldTitle}
                    gutterBottom
                  >
                    Nom et prénom
                    <span className={classes.marker}>*</span>
                  </Typography>
                  <Grid item>
                    <FormControl fullWidth>
                      <Typography
                        component="legend"
                        className={classes.fieldTitleSecondary}
                      >
                        Name and Surname
                      </Typography>
                      <Grid item className={classes.inputWrapper}>
                        <FormTextField
                          fullWidth
                          id="notification.customerName"
                          name="notification.customerName"
                          variant="outlined"
                          formik={formik}
                          className={classes.input}
                        />
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {/* Submit button */}
              {formik?.values?.providerType && (
                <Grid item className={classes.inputWrapper}>
                  {isPaypal && (
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                      }}
                      mb={1}
                    >
                      {translation.paypalRedirection}
                    </Typography>
                  )}
                  <ButtonStyled
                    disabled={isSubmitDisabled}
                    type="submit"
                    label={isPaypal ? "" : translation.paymentButton}
                    icon={
                      isPaypal && <img src={paypalIcon} alt="paymentIcon" />
                    }
                    style={{
                      width: "100%",
                      backgroundColor: isSubmitDisabled
                        ? theme?.primaryColor
                        : theme?.secondaryColor
                        ? theme.secondaryColor
                        : isPaypal
                        ? palette.orange
                        : palette.primary.main,
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Grid className={classes.checkBoxWrapper} container direction="column">
          {!checked && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                color: "#d32f2f",
                marginBottom: 4,
              }}
            >
              <WarningAmberRoundedIcon sx={{ marginRight: 1, fontSize: 20 }} />
              <Typography
                style={{
                  fontSize: "0.75rem",
                }}
              >
                {translation.paymentTerms}
              </Typography>
            </Box>
          )}
          <FormControlLabel
            className={classes.checkBox}
            label={terms}
            control={
              <Checkbox
                checked={checked}
                onChange={handleChecked}
                sx={{
                  "&.Mui-checked": {
                    color: theme?.primaryColor
                      ? theme?.primaryColor
                      : palette.primary.main,
                  },
                }}
              />
            }
          />
        </Grid>
      </form>
    </>
  );
};
export default FormDetailsDesktop;

const terms = (
  <React.Fragment>
    <Typography>
      J’accepte les
      <Link
        underline="none"
        color="#4784F9"
        href="https://enkap.cm/politique-de-confidentialite/"
      >
        {" "}
        termes et les conditions
      </Link>
    </Typography>
    <Typography>
      I agree to the
      <Link
        underline="none"
        color="#4784F9"
        href="https://enkap.cm/privacy-policy/?lang=en"
      >
        {" "}
        terms and conditions
      </Link>
    </Typography>
  </React.Fragment>
);

const Label = ({ name, onClick }) => {
  let logo = [
    { logo: mtn, name: "mtn" },
    { logo: orangeV2, name: "orangeV2" },
    { logo: smobilpay, name: "smobilpay" },
    { logo: expressunion, name: "expressunion" },
    { logo: yoomee, name: "yoomee" },
  ];
  let image = logo.find((i) => (i.name === name ? i.logo : null));
  return <img onClick={onClick} src={image?.logo} alt={name} loading="lazy" />;
};
